import React, { useState, useEffect } from "react";
import {
  Container, Grid, Paper, TextField, Typography, InputAdornment, Link, Stack, Button,
  RadioGroup, Radio, Dialog, DialogContent, DialogActions, Slide, DialogTitle, IconButton, FormControlLabel, FormControl,
} from "@mui/material";
import "./SignupForm.css";
import UrlRoute from "../API/UrlRoute";
import Constants from "../MeddyConnectConstant";
import YLoader from "../components/Loader";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Logo from "../components/Logo";
import { useFormValidation } from "../components/FormValidation";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import useAPIRequest from "../API/useApiRequest";
import userPublicAPIRequest from "../API/useApiPublicRequest"
import useTokenService from "../utils/useTokenService";
import { YContext } from "../Context/YContext";
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Login = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);

  // Variables
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewConfirmedPassword, setShowNewConfirmedPassword] = useState(false);
  const [userType, setUserType] = React.useState("");
  const { addToast } = useToasts();
  const [otp, setOtp] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const { setCurrentUser, setIsAuthenticated } = React.useContext(YContext);
  const [userData, setUserData] = useState({
    userId: "",
    countryCode: "",
    contactNo: "",
  });
  const history = useHistory();
  const [forgotMobileNo, setForgotMobileNo] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newConfirmedPassword, setNewConfirmedPassword] = useState("");

  // Dialogs
  const [openSignupDialog, setOpenSignupDialog] = useState(false);
  const [openForgotPasswordDialog, setOpenForgotPasswordDialog] = useState(false);
  const [openMobileOTPDialog, setOpenMobileOTPDialog] = useState(false);
  const [openNewPasswordDialog, setOpenNewPasswordDialog] = useState(false);

  // Services
  const { apiRequests } = useAPIRequest();
  const { apiPublicRequests } = userPublicAPIRequest();
  const { getOrGenerateToken, saveToken, saveCredentials } = useTokenService();

  const { values, errors, bindField, isValid } = useFormValidation({
    validations: {
      mobile: {
        pattern: {
          value: /^\d{10}$/,
          message: "Invalid WhatsApp number",
        },
        required: 'The field "WhatsApp number" is required',
      },
      password: {
        pattern: {
          value: /^(.*?)$/,
          message: "Invalid password",
        },
        required: 'The field "password" is required',
      },
    },
    initialValues: {},
    errorsFields: { mobile: false, password: false }, // set error fields initially as false 
  });

  const redirct = () => {
    if (userType === Constants.DOCTOR) {
      history.push("/doctorsignup");
    } else if (userType === Constants.HOSPITAL) {
      history.push("/hospitalsignup");
    } else if (userType === Constants.VENDOR) {
      history.push("/vendorsignup");
    } else if (userType === Constants.STUDENT) {
      history.push("/studentsignup");
    } else if (userType === Constants.STAFF) {
      history.push("/staffsignup");
    } else if (userType === Constants.ASSOCIATION) {
      history.push("/medicalassociationsignup");
    }
  };

  // Function to check if user is already logged in
  const autoLogin = async () => {
    setLoading(true);
    const countryCode = localStorage.getItem(Constants.COUNTRY_CODE);

    if (countryCode !== null) {

      const token = await getOrGenerateToken();
      if (token) {
        history.push(Constants.HOME_SCREEN);
      } else {
        setLoading(false);
        addToast("Something went wrong!", { appearance: "error" });
      }

    } else {
      setLoading(false);
    }

  };

  useEffect(() => {
    autoLogin();
  }, [])

  // User Type Select - Signup
  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
  };

  // Signup Dialogs Events
  const handleSignupDialogClickOpen = () => {
    setOpenSignupDialog(true);
  };

  const handleSignupDialogClickClose = () => {
    setOpenSignupDialog(false);
  };

  // Forget Password Dialog Events
  const handleForgetPasswordDialogClickOpen = () => {
    setOpenForgotPasswordDialog(true);
  };

  const handleForgetPasswordDialogClickClose = () => {
    setOpenForgotPasswordDialog(false);
  };

  // Mobile OTP Dialog Events
  const handleMobileOTPDialogClickOpen = () => {
    setOpenMobileOTPDialog(true);
  };

  const handleMobileOTPDialogClickClose = () => {
    setOpenMobileOTPDialog(false);
  };

  // New Password Dialog Event
  const handleNewPasswordDialogClickOpen = () => {
    setOpenNewPasswordDialog(true);
  };

  const handleNewPasswordDialogClickClose = () => {
    setOpenNewPasswordDialog(false);
  };

  // Functions
  const generateOTP = async () => {
    handleForgetPasswordDialogClickClose();
    setLoading(true);
    const { response, data } = await apiPublicRequests({
      endPoint: UrlRoute.GENERATE_OTP_URL,
      method: "POST",
      body: {
        countryCode: "+91",
        contactNo: forgotMobileNo,
      },
      addToast,
    });
    if (data) {
      setUserData({ userId: data.userId, countryCode: data.countryCode, contactNo: data.contactNo })
      // setOtp(data.otp);
      setLoading(false);
      handleMobileOTPDialogClickOpen();
    } else if (response.status === 400) {
      setLoading(false);
      addToast("The WhatsApp number provided does not match any account. Please double-check the WhatsApp number or sign up if you don't have an account.", { appearance: "error" });
    } else {
      setLoading(false);
      addToast("Something went wrong!", { appearance: "error" });
    }
  };

  const forgotPassword = async () => {
    setLoading(true);
    const { response, data } = await apiPublicRequests({
      endPoint: UrlRoute.RESET_PASSWORD_URL,
      method: "POST",
      body: {
        otp: otp,
        ...userData
      },
      addToast,
    });
    if (data) {
      saveToken(data.token);
      handleMobileOTPDialogClickClose();
      setLoading(false);
      handleNewPasswordDialogClickOpen();
    } else if (response.status === 400) {
      setLoading(false);
      addToast("Please enter valid WhatsApp Number OTP!", { appearance: "error" });
    } else {
      setLoading(false);
      addToast("Something went wrong!", { appearance: "error" });
    }

  };

  const updatePassword = async () => {
    setLoading(true);
    const { response } = await apiRequests({
      endPoint: UrlRoute.UPDATE_PASSWORD_URL,
      method: "PUT",
      body: {
        id: userData.userId,
        password: newConfirmedPassword,
      },
      addToast,
    });
    if (response.status === 200) {
      handleNewPasswordDialogClickClose();
      setLoading(false);
      addToast("Password changed successfully!", { appearance: "success" });
    } else {
      setLoading(false);
      addToast("Error occurred while Password change!", { appearance: "error" });
    }
  };

  const getUser = async () => {
    const { data } = await apiRequests({
      endPoint: UrlRoute.USER_INFO_URL,
      method: "GET",
      addToast,
    });
    return data;
  };

  // When user click on Login Button
  const loginClicked = async () => {
    setLoading(true);
    saveCredentials(countryCode, values.mobile, values.password);
    const token = await getOrGenerateToken();
    if (token) {
      const data = await getUser();
      if (data) {
        setCurrentUser(data);
        setIsAuthenticated(true);
        setLoading(false);
        addToast("Logged in successfully!", { appearance: "success" });
        history.push(Constants.HOME_SCREEN);
      } else {
        setLoading(false);
        addToast("Something went wrong during login. Please try again later.!", { appearance: "error" });
      }
    } else {
      setLoading(false);
    }

  };

  const validatePassword = (newPassword, newConfirmedPassword) => {

    if (newPassword === newConfirmedPassword) {
      updatePassword();
    } else {
      addToast("Password does not matched", { appearance: "error" });
    }

  };

  return (
    <div>
      {/* Login Page */}
      <Container maxWidth="sm">
        <Grid container spacing={0}
          direction="column"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Paper elevation={24} className="paper">
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Stack direction="row" justifyContent="center">
                  <Logo />
                </Stack>
              </Grid>
              <Grid item>
                <Typography
                  align="left"
                  variant="h6"
                  style={{ marginBottom: "-0.8em" }}
                  color="text.primary"
                >
                  Login to your account
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  placeholder="WhatsApp number"
                  name="mobile"
                  id="mobile"
                  autoComplete="off"
                  inputProps={{ maxLength: 10, type: "tel" }}
                  {...bindField("mobile")}
                  error={errors.mobile == "" ? false : true}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <WhatsAppIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.mobile}
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  id="password"
                  {...bindField("password")}
                  error={errors.password == "" ? false : true}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="start"
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.password}
              </Grid>
              <Grid item>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Link
                    style={{ textDecoration: "none" }}
                    href="forgot-password"
                    variant="body1"
                    color="primary"
                    component="button"
                    onClick={handleForgetPasswordDialogClickOpen}
                  >
                    Forgot?
                  </Link>
                </Stack>
              </Grid>
              <Grid item >
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className="btn-primary"
                  onClick={loginClicked}
                  disabled={!isValid()}
                >
                  Log In
                </Button>
              </Grid>
              <Grid item>
              <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className="btn-primary"
                  onClick={handleSignupDialogClickOpen}
                >
                  New User Registration
                </Button>
                {/* <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignContent="flex-end"
                  style={{ marginTop: "1em" }}
                >
                  <Typography variant="body1" align="center" color="text.primary">
                    Don't have an account ?
                    <Link
                      component="button"
                      onClick={handleSignupDialogClickOpen}
                      style={{
                        fontWeight: "bold",
                        textDecoration: "none",
                        marginLeft: "0.5em",
                      }}
                      variant="body1"
                      color="primary"
                    >
                      Sign Up
                    </Link>
                  </Typography>
                </Stack> */}
              </Grid>
            </Grid>

            {/* Signup Dialog */}
            <Dialog
              open={openSignupDialog}
              onClose={handleSignupDialogClickClose}
              fullScreen={fullScreen}
              maxWidth="sm"
              fullWidth
              TransitionComponent={Transition}>
              <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                <IconButton
                  edge="start"
                  onClick={handleSignupDialogClickClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon style={{ color: "white" }} />
                </IconButton>
                Sign up as
              </DialogTitle>
              <DialogContent style={{ padding: "0px 14px 14px 14px" }}>
                <Grid
                  container
                  spacing={0}
                  justifyContent="center"
                  alignContent="center"
                >
                  <Grid item xs={12} >
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={userType}
                        onChange={handleUserTypeChange}
                      >
                        <FormControlLabel
                          value="doctor"
                          control={<Radio />}
                          label="Doctor"
                        />
                        <FormControlLabel
                          value="hospital"
                          control={<Radio />}
                          label="Hospital/Clinic"
                        />
                        <FormControlLabel
                          value="vendor"
                          control={<Radio />}
                          label="Vendor"
                        />
                        <FormControlLabel
                          value="medical student"
                          control={<Radio />}
                          label="Medical Student"
                        />
                        <FormControlLabel
                          value="medical staff"
                          control={<Radio />}
                          label="Medical Staff"
                        />
                        <FormControlLabel
                          value="medical association"
                          control={<Radio />}
                          label="Medical Association"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions style={{ padding: "0px 12px 16px 12px" }}>
                <Grid
                  container
                  spacing={0}
                  justifyContent="center"
                  alignContent="center"
                >
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      className="dialog-button"
                      onClick={redirct}
                    >
                      Continue
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>

            {/* Forget Password Dialog */}
            <Dialog
              open={openForgotPasswordDialog}
              onClose={handleForgetPasswordDialogClickClose}
              fullScreen={fullScreen}
              maxWidth="sm"
              fullWidth
              TransitionComponent={Transition}
            >
              <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white", padding: "16px 14px" }}>
                <IconButton
                  edge="start"
                  onClick={handleForgetPasswordDialogClickClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon style={{ color: "white" }} />
                </IconButton>
                Forgot Password
              </DialogTitle>
              <DialogContent style={{ padding: "14px 14px 14px 14px" }}>
                <Grid
                  container
                  spacing={0}
                  justifyContent="center"
                  alignContent="center"
                >
                  <Grid item xs={12} >
                    <Typography
                      align="left"
                      variant="h6"
                      color="text.primary"
                    >
                      Enter Your WhatsApp Number
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder="WhatsApp number"
                      autoComplete="off"
                      name="forgotmobile"
                      id="forgotmobile"
                      value={forgotMobileNo}
                      onChange={(event) => setForgotMobileNo(event.target.value)}
                      inputProps={{ maxLength: 10, type: "tel" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <WhatsAppIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions style={{ padding: "0px 12px 16px 12px" }}>
                <Grid
                  container
                  spacing={0}
                  justifyContent="center"
                  alignContent="center"
                >
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      className="dialog-button"
                      onClick={generateOTP}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>

            {/* OTP Dialog */}
            <Dialog
              open={openMobileOTPDialog}
              // onClose={handleMobileOtpDialogClose}
              fullScreen={fullScreen}
              maxWidth="sm"
              fullWidth
              TransitionComponent={Transition}
            >
              <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white", padding: "16px 14px" }}>
                <IconButton
                  edge="start"
                  onClick={handleMobileOTPDialogClickClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon style={{ color: "white" }} />
                </IconButton>
                OTP Verification
              </DialogTitle>
              <DialogContent style={{ padding: "14px 14px 14px 14px" }}>
                <Grid
                  container
                  spacing={0}
                  justifyContent="center"
                  alignContent="center"
                >
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      color="primary"
                      id="_otp"
                      label="WhatsApp Number OTP"
                      autoComplete="off"
                      inputProps={{ maxLength: 6 }}
                      autoFocus={true}
                      variant="outlined"
                      value={otp}
                      onChange={(event) => setOtp(event.target.value)}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions style={{ padding: "0px 12px 16px 12px" }}>
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  alignContent="center"
                >
                  <Grid item xs={6} sm={6}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      className="dialog-button"
                      onClick={forgotPassword}
                    >
                      Submit
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      className="dialog-button"
                      onClick={generateOTP}
                    >
                      Resend
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>

            {/* New Password Dialog */}
            <Dialog
              open={openNewPasswordDialog}
              // onClose={handleNewPasswordDialogClose}
              fullScreen={fullScreen}
              maxWidth="sm"
              fullWidth
              TransitionComponent={Transition}
            >
              <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white", padding: "16px 14px" }}>
                <IconButton
                  edge="start"
                  onClick={handleNewPasswordDialogClickClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon style={{ color: "white" }} />
                </IconButton>
                Update Password
              </DialogTitle>
              <DialogContent style={{ padding: "14px 14px 14px 14px" }}>
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  alignContent="center"
                >
                  <Grid item xs={12} >
                    <Typography
                      align="left"
                      variant="h6"
                      color="text.primary"
                    >
                      Create a New password
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type={showNewPassword ? "text" : "password"}
                      label="New Password"
                      value={newPassword}
                      onChange={(event) => setNewPassword(event.target.value)}
                      name="newpassword"
                      id="newpassword"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              aria-label="toggle new password visibility"
                              onClick={() => setShowNewPassword(!showNewPassword)}
                              edge="start"
                            >
                              {showNewPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: "6px" }}>
                    <TextField
                      fullWidth
                      type={showNewConfirmedPassword ? "text" : "password"}
                      label="Confirm Password"
                      value={newConfirmedPassword}
                      onChange={(event) => setNewConfirmedPassword(event.target.value)}
                      name="newconfirmedpassword"
                      id="newconfirmedpassword"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              aria-label="toggle new password visibility"
                              onClick={() => setShowNewConfirmedPassword(!showNewConfirmedPassword)}
                              edge="start"
                            >
                              {showNewConfirmedPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions style={{ padding: "0px 12px 16px 12px" }}>
                <Grid
                  container
                  spacing={0}
                  justifyContent="center"
                  alignContent="center"
                >
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      className="dialog-button"
                      onClick={() => { validatePassword(newPassword, newConfirmedPassword) }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
        <YLoader loading={loading} />
      </Container>
    </div>
  );
};

export default Login;
